import React, { useState, useEffect } from "react";
import Slider from "@mui/material/Slider";
import "./CloudConnectBackup.css";
import VeeamOnsite from "./PriceFiles/VeeamOnsite.json";
import Box from "@mui/material/Box";
import {
  Row,
  Col,
  Form,
  InputGroup,
  Card,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { InfoCircleFill } from "react-bootstrap-icons";
import VirtualDCS from "../src/Images/virtualDCS-logo.png";
import Veeam from "../src/Images/veeam.png";
export const VeeamLocalBackup = (props) => {
  const [virtualMachineValue, setVirtualMachineValue] = useState(0);
  const [physicalMachineValue, setPhysicalMachineValue] = useState(0);
  const [workstationValue, setWorkstationValue] = useState(0);
  const [dataProtectValue, setDataProtectValue] = useState(0);
  const [physicalMachineCost] = useState(VeeamOnsite.Physical.PerPrice);
  const [workstationCost] = useState(VeeamOnsite.Workstation.PerPrice);
  const [vmCost, setVMCost] = useState(() => {
    for (let index = 0; index < VeeamOnsite.Products.length; index++) {
      const element = VeeamOnsite.Products[index];
      if (element.ProductName === "Veeam Availability Suite Enterprise Plus") {
        return element.ProductPrice;
      }
    }
  });
  const [price, setPrice] = useState(0.0);
  const [total, setTotal] = useState(0.0);
  const [localBackupOptions, setLocalBackupOptions] = useState();
  const [selectedLocalBackupOption, setSelectedLocalBackupOption] = useState(
    "Veeam Availability Suite Enterprise Plus"
  );

  const OnChangeValue = (event) => {
    const targetValue = event.target.value;
    const targetName = event.target.name;

    switch (targetName) {
      case "virtualMachine":
        setVirtualMachineValue(targetValue === "" ? 0 : Number(targetValue));
        calculatePrice(
          Number(dataProtectValue),
          Number(targetValue),
          physicalMachineValue,
          workstationValue,
          vmCost
        );
        break;
      case "physicalMachine":
        setPhysicalMachineValue(targetValue === "" ? 0 : Number(targetValue));
        calculatePrice(
          Number(dataProtectValue),
          virtualMachineValue,
          Number(targetValue),
          workstationValue,
          vmCost
        );
        break;
      case "workstation":
        setWorkstationValue(targetValue === "" ? 0 : Number(targetValue));
        calculatePrice(
          Number(dataProtectValue),
          virtualMachineValue,
          physicalMachineValue,
          Number(targetValue),
          vmCost
        );
        break;
      default:
        break;
    }
  };

  const handleSliderChange = (event, newValue) => {
    setDataProtectValue(newValue);
    calculatePrice(
      newValue,
      virtualMachineValue,
      physicalMachineValue,
      workstationValue,
      vmCost
    );
  };

  const handleInputChange = (event) => {
    setDataProtectValue(
      event.target.value === "" ? 0 : Number(event.target.value)
    );
    calculatePrice(
      Number(event.target.value),
      virtualMachineValue,
      physicalMachineValue,
      workstationValue,
      vmCost
    );
  };

  const calculatePrice = (
    newValue,
    virtaulMachine,
    physicalMachine,
    workStation,
    virtaulMachineCost,
    discount = 0
  ) => {
    console.log(newValue);
    var cost = 0;
    var slideqty = newValue * 1000;
    for (let index = 0; index < VeeamOnsite.DiskBand.length; index++) {
      const element = VeeamOnsite.DiskBand[index];
      if (slideqty <= JSON.parse(element.Start)) {
        cost = slideqty * element.Price;
        break;
      }
      else if (slideqty > JSON.parse(element.Start)) {
        cost = slideqty * element.Price;
      }
    }
    if (slideqty !== 0) {
      var disc = 1 - Number(discount) / 100;
      var amount = Number(cost / slideqty);
      setPrice((amount * disc).toFixed(3));
    } else {
      setPrice(0);
    }

    var vmPrice = virtaulMachine * virtaulMachineCost;
    var physicalMachinePrice = physicalMachine * physicalMachineCost;
    var workStationPrice = workStation * workstationCost;
    setTotal(
      (
        Number(vmPrice) +
        Number(physicalMachinePrice) +
        Number(workStationPrice) +
        Number(cost)
      ).toFixed(2)
    );
  };

  const handleBlur = () => {
    if (dataProtectValue < 0) {
      setDataProtectValue(0);
    } else if (dataProtectValue > 50) {
      setDataProtectValue(50);
    }
  };

  function valuetext(value) {
    return `${value} TB`;
  }

  const marks = [
    {
      value: 0,
      label: "0 TB",
    },
    {
      value: 25,
      label: "25 TB",
    },
  ];
const fivezeromarks = [
  {
    value: 0,
    label: "0 TB",
  },
  {
    value: 50,
    label: "50 TB",
  },
];
  function setLocalBackupDropdown() {
    let localBackupOptions = [];
    for (var i = 0; i < VeeamOnsite.Products.length; i++) {
      var dropdownItem = (
        <option key={i} value={VeeamOnsite.Products[i].ProductName}>
          {VeeamOnsite.Products[i].ProductName}
        </option>
      );
      localBackupOptions.push(dropdownItem);
    }

    setLocalBackupOptions(localBackupOptions);
  }

  const localBackupOptionsChanged = (event, newValue) => {
    var serverPrice = 0;
    for (let index = 0; index < VeeamOnsite.Products.length; index++) {
      const element = VeeamOnsite.Products[index];
      if (element.ProductName === event.target.value) {
        setSelectedLocalBackupOption(event.target.value);
        setVMCost(element.ProductPrice);
        serverPrice = element.ProductPrice;
        break;
      }
    }
    calculatePrice(
      Number(dataProtectValue),
      Number(virtualMachineValue),
      Number(physicalMachineValue),
      Number(workstationValue),
      Number(serverPrice)
    );
  };

  useEffect(() => {
    setLocalBackupDropdown();
  }, []);
  const renderTooltip = (props) => (
    <Tooltip id={`tooltip-${props.id}`} {...props}>
      {props.text}
    </Tooltip>
  );
  return (
    <>
      <Row className="p-3 pe-4" style={{ borderRadius: "8px" }}>
        <Col sm={8}>
          <Card style={{ borderColor: "white" }}>
            <Card.Body>
              <Row>
                <Col sm={12} className="justify-content-center d-flex">
                  <span className="font-weight-bold textprimary mb-3 labels">
                    Choose your local backup software option
                  </span>
                </Col>
                <Col sm={12} className="justify-content-center d-flex">
                  <Col sm={6} className="mb-3">
                    <Form.Select
                      aria-label="Default select example"
                      value={selectedLocalBackupOption}
                      onChange={localBackupOptionsChanged}
                      className="mb-1"
                    >
                      {localBackupOptions}
                    </Form.Select>
                    <span className="p-2" style={{ color: "gray" }}>
                      If you’re not sure which option is appropriate for your
                      business, we’re happy to advise.
                    </span>
                  </Col>
                </Col>
                <Col sm={12} className="justify-content-center d-flex">
                  <span className="font-weight-bold textprimary me-2 mb-3 labels">
                    Number of workloads to protect
                  </span>
                  <OverlayTrigger
                    key="sort-1"
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={(props) =>
                      renderTooltip({
                        ...props,
                        id: "sort-1",
                        text: "Select the breakdown of your workloads Virtual Machines - VM's Physical Server - Non virtualized machines Workstations - Desktops",
                      })
                    }
                  >
                    <InfoCircleFill color="#5091ce" />
                  </OverlayTrigger>
                </Col>
                <Col sm={12} className="justify-content-center d-flex">
                  <Col sm={6}>
                    <Col sm={12} className="d-flex">
                      <span style={{ width: "100%" }}>Virtual Machine</span>

                      <Form.Control
                        id="virtualMachineId"
                        name="virtualMachine"
                        type="number"
                        value={virtualMachineValue}
                        placeholder="0"
                        aria-label="VM(s)"
                        onChange={OnChangeValue}
                        min="0"
                        step="1"
                        className="mb-3"
                      />
                    </Col>
                    <Col sm={12} className="d-flex">
                      <span style={{ width: "100%" }}>Physical Machine</span>

                      <Form.Control
                        id="physicalMachineId"
                        name="physicalMachine"
                        type="number"
                        value={physicalMachineValue}
                        placeholder="0"
                        aria-label="Physical machines"
                        onChange={OnChangeValue}
                        min="0"
                        step="1"
                        className="mb-3"
                      />
                    </Col>
                    <Col sm={12} className="d-flex">
                      <span style={{ width: "100%" }}>WorkStations</span>

                      <Form.Control
                        id="physicalMachineId"
                        name="workstation"
                        type="number"
                        value={workstationValue}
                        placeholder="0"
                        aria-label="WorkStations"
                        onChange={OnChangeValue}
                        min="0"
                        step="1"
                        className="mb-3"
                      />
                    </Col>
                  </Col>
                </Col>
                <Col sm={12} className="d-flex">
                  <span className="font-weight-bold textprimary me-2 labels">
                    Data to protect offsite
                  </span>
                  <OverlayTrigger
                    key="sort-1"
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={(props) =>
                      renderTooltip({
                        ...props,
                        id: "sort-1",
                        text: "Estimate the amount of off-site disk space you require to store your data. i.e. if you already have some, how large are your local vbk files?",
                      })
                    }
                  >
                    <InfoCircleFill color="#5091ce" />
                  </OverlayTrigger>
                </Col>
                <Col sm={10}>
                  <Slider
                    min={0}
                    max={50}
                    step={0.5}
                    getAriaValueText={valuetext}
                    value={
                      typeof dataProtectValue === "number"
                        ? dataProtectValue
                        : 0
                    }
                    onChange={handleSliderChange}
                    aria-labelledby="input-slider"
                    valueLabelDisplay="auto"
                    marks={fivezeromarks}
                  />
                </Col>
                <Col sm={2}>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="number"
                      value={dataProtectValue}
                      placeholder="0"
                      aria-label="TB"
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      min="0"
                      max="50"
                      step="0.5"
                      aria-describedby="basic-addon1"
                    />
                    <InputGroup.Text id="basic-addon1">TB</InputGroup.Text>
                  </InputGroup>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={4} className="modern-card">
          {props.isBlurred ? (
            <>
              <div
                onClick={props.handleShow}
                className="heartbeat p-2 ps-4 pe-4 four"
              >
                <span>Show Pricing</span>
              </div>
              <div className={`blurred-card blur bllurred-four p-2`}>
                <Card className="shadow-sm rounded-lg">
                  <Card.Body>
                    <Card.Title className="font-weight-bold textprimary">
                      Cost
                    </Card.Title>
                    <Card.Text className="font-weight-medium">
                      £&nbsp;0/ Server
                    </Card.Text>
                    <Card.Text className="font-weight-medium">
                      £&nbsp;0/ Physical server
                    </Card.Text>
                    <Card.Text className="font-weight-medium">
                      £&nbsp;0/ Workstation
                    </Card.Text>
                    <Card.Title className="font-weight-bold textprimary mt-3">
                      Price
                    </Card.Title>
                    <Card.Text className="font-weight-medium">
                      £&nbsp;0/ GB
                    </Card.Text>
                    <Card.Title className="font-weight-bold textprimary mt-3">
                      Total price excluding VAT
                    </Card.Title>
                    <Card.Text className="font-weight-medium">
                      £&nbsp;0/ mo
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </>
          ) : (
            <>
              <div
                className="p-2"
                style={{
                  backgroundImage:
                    "linear-gradient(to right, #6dd6eb, #0c33a6)",
                  borderRadius: "13px",
                  width: "100%",
                }}
              >
                <Card className="shadow-sm rounded-lg">
                  <Card.Body>
                    <Card.Title className="font-weight-bold textprimary">
                      Cost
                    </Card.Title>
                    <Card.Text className="font-weight-medium">
                      £&nbsp;{vmCost}/ Server
                    </Card.Text>
                    <Card.Text className="font-weight-medium">
                      £&nbsp;{physicalMachineCost}/ Physical server
                    </Card.Text>
                    <Card.Text className="font-weight-medium">
                      £&nbsp;{workstationCost}/ Workstation
                    </Card.Text>
                    <Card.Title className="font-weight-bold textprimary mt-3">
                      Price
                    </Card.Title>
                    <Card.Text className="font-weight-medium">
                      £&nbsp;{price}/ GB
                    </Card.Text>
                    <Card.Title className="font-weight-bold textprimary mt-3">
                      Total price excluding VAT
                    </Card.Title>
                    <Card.Text className="font-weight-medium">
                      £&nbsp;{total}/ mo
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </>
          )}
        </Col>
      </Row>
      <Box className="p-3 pt-0 scaleup">
        <div
          className="bg-white p-3"
          style={{
            borderRadius: "8px",
            border: "1px solid #5091ce",
            textAlign: "left",
          }}
        >
          <div className="col-12 justify-content-start d-flex">
            <span className="text-muted me-1">
              * Click{" "}
              <a
                href="#"
                style={{ fontSize: "13px" }}
                className="text-primary text-decoration-underline me-1"
                onClick={props.handleRequestShow}
              >
                HERE
              </a>{" "}
              to claim a 30-day trial.
            </span>
          </div>
          <div className="col-12 justify-content-start d-flex">
            <span className="text-muted">
              * This pricing is intended as a guide, and we are happy to discuss
              your requirements in more detail.
            </span>
          </div>
          <div className="col-12 justify-content-start d-flex">
            <span className="text-muted me-1">
              * For full service terms and conditions click{" "}
              <a
                href="https://www.virtualdcs.co.uk/downloads"
                style={{ fontSize: "13px" }}
                className="text-primary text-decoration-underline"
              >
                {" "}
                HERE.
              </a>
            </span>
          </div>
        </div>
      </Box>
    </>
  );
};

export default VeeamLocalBackup;
