import React from 'react';
import './App.css'; 
import PricingTabContent from './PricingTabContent';

function App() {
  return (
    <div className="App">
      
      <main className="App-main">
        <PricingTabContent/>
      </main>
      
    </div>
  );
}

export default App;