import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Tab,
  Tabs,
  Modal,
  Button,
  Form,
  Row,
  Col,
  Card,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import "./Css/TabCss.css";
import Axios from "axios";
import CloudConnectBackup from "./CloudConnectBackup";
import CloudConnectReplication from "./CloudConnectReplication";
import Microsoft365Backup from "./Microsoft365Backup";
import VeeamLocalBackup from "./VeeamLocalBackup";
import VirtualDCS from "../src/Images/virtualDCS-logo.png";
import PricingToolHeaderBG from "../src/Images/PricingToolHeaderBG.jpg";
import Veeam from "../src/Images/veeam.png";
import "bootstrap/dist/css/bootstrap.css";
import Microsoft365Pricing from "./Microsoft365Pricing";
export default function PricingTabContent() {
  const [activeKey, setActiveKey] = useState("1");
  const [isBlurred, setIsBlurred] = useState(true);
  const [show, setShow] = useState(false);
  const [showRequest, setShowRequest] = useState(false);
  const [imageUrl, setImageUrl] = useState(
    "https://www.virtualdcs.co.uk/files/pricing-tool-images/6.png"
  );
  const [heading, setHeading] = useState(
    <>
      <div className="Title">CloudCover Pricing Tool</div>
    </>
  );
  const [displayTab, setDisplayTab] = useState(false);

  const [qparams, setQparams] = useState(
    new URLSearchParams(window.location.search)
  );
  const [resellerId, setResellerId] = useState(0);
  const [displayOnly, setDisplayOnly] = useState(true);
  const [planDetails, setPlanDetails] = useState([]);
  const [showLoader, setShoeLoader] = useState(true);
  const [collapsed, setCollapsed] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage,setToastMessage]=useState("");
  const [toastVariant,setToastVariant]=useState("");
  const [hideRRP, setHideRRP] = useState(true);
  const [data, setData] = useState({
    name: "",
    isNameValid: true,
    nameErrorMessage: "",
    businessEmail: "",
    isBusinessValid: true,
    businessEmailErrorMessage: "",
    company: "",
    isCommpanyValid: true,
    companyErrorMessage: "",
    number: "",
    isNumberValid: true,
    numberErrorMessage: "",
    comments: "",
    isCommentsValid: true,
    commentsErrorMessage: "",
  });
  const handleClose = () => {
    setShow(false);
    setData({
      ...data,
      isNameValid: true,
      nameErrorMessage: "",
      isBusinessValid: true,
      businessEmailErrorMessage: "",
    });
  };
  const handleShow = () => setShow(true);
  const handleRequestClose = () => setShowRequest(false);
  const handleRequestShow = () => setShowRequest(true);
  const togglePriceBlur = async () => {
    let config = {
      headers: {
        Authorisation: "Bearer Token not need for Display only",
      },
    };

    if ( data.isNameValid && data.isBusinessValid && data.name !== "" && data.businessEmail !== "" ) {
      const response = await Axios.get(
        "https://365Api-preview.virtualdcs.co.uk:443/api/Email/ViewPriceModel/" +
          data.name +
          "/" +
          data.businessEmail,
        config
      )
        .then((response) => {
          setToastVariant("success")
          setToastMessage('Successfully submitted')
          setShowToast(true); 
          setIsBlurred(!isBlurred);
        })
        .catch((reason) => {
          console.log(reason);
          setShoeLoader(false);
        });
    } else {
      setToastVariant("danger")
      setToastMessage('Please fill in all required fields.')
      setShowToast(true);
    }
    handleClose();
  };

  const handleRequestSubmit = async () => {
    let config = {
      headers: {
        Authorisation: "Bearer Token not need for Display only",
      },
    };
    if ( data.isNameValid && data.isBusinessValid && data.isCommpanyValid && data.isNumberValid && data.isCommentsValid && data.name !== "" && data.businessEmail !== "" && data.company !== ""&& data.number !== ""&& data.comments !== ""){
      const response = await Axios.get(
        "https://365Api-preview.virtualdcs.co.uk:443/api/Email/TrailRequest/" +
          data.name +
          "/" +
          data.businessEmail +
          "/" +
          data.company +
          "/" +
          data.number +
          "/" +
          data.comments,
        config
      )
        .then((response) => {
          setToastVariant("success")
          setToastMessage('Successfully submitted')
          setShowToast(true); 
          setIsBlurred(!isBlurred);
        })
        .catch((reason) => {
          console.log(reason);
          setShoeLoader(false);
        });
    }
    else{
      setToastVariant("danger")
      setToastMessage('Please fill in all required fields.')
      setShowToast(true);
    }
    handleRequestClose();
  };
  const handleSelect = (key) => {
    setActiveKey(key);
    if (key === "1") {
      setImageUrl(
        "https://www.virtualdcs.co.uk/files/pricing-tool-images/2.png"
      );
      setHeading(
        <>
          <div className="Title">Veeam Cloud Connect Backup</div>
          <div className="SubTitle">Off Site Backup & Air Gap</div>
        </>
      );
    } else if (key === "2") {
      setImageUrl(
        "https://www.virtualdcs.co.uk/files/pricing-tool-images/5.png"
      );
      setHeading(
        <>
          <div className="Title">Veeam Cloud Connect Replication</div>
          <div className="SubTitle">Off Site Replication and Failover</div>
        </>
      );
    } else if (key === "3") {
      setImageUrl(
        "https://www.virtualdcs.co.uk/files/pricing-tool-images/4.png"
      );
      setHeading(
        <>
          <div className="Title">CloudCover 365</div>
          <div className="SubTitle">Back up your Microsoft Office 365</div>
        </>
      );
    } else if (key === "4") {
      setImageUrl(
        "https://www.virtualdcs.co.uk/files/pricing-tool-images/3.png"
      );
      setHeading(
        <>
          <div className="Title">Veeam Local Backup</div>
          <div className="SubTitle">
            The complete on site & off site backup solution
          </div>
        </>
      );
    }
  };
  const handleNameChange = (params) => {
    if (params.target.value !== "") {
      setData({
        ...data,
        name: params.target.value,
        isNameValid: true,
        nameErrorMessage: "",
      });
    } else {
      setData({
        ...data,
        name: params.target.value,
        isNameValid: false,
        nameErrorMessage: "Name should not be empty",
      });
    }
  };
  const bannedDomains = [
    "aol.com", "facebook.com", "gmail.com", "googlemail.com", "google.com",
    "hotmail.com", "hotmail.co.uk", "mac.com", "me.com", "mail.com", "msn.com",
    "yahoo.com", "yahoo.co.uk", "ymail.com", "protonmail.com", "bob.com"
  ];
  const isValidEmailAddress = (address) => {
    const regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      
  
      if (!regEmail.test(address)) {
          return false;
      }
  
      const domain = address.split('@')[1];
      if (bannedDomains.includes(domain)) {
          return false;
      }
  
      return true;
  };
  const handleBusiness = (params) => {
    const domain = params.target.value.split('@')[1];
    if (
      params.target.value !== "" &&
      isValidEmailAddress(params.target.value)
    ) {
      setData({
        ...data,
        businessEmail: params.target.value,
        isBusinessValid: true,
        businessEmailErrorMessage: "",
      });
    } else {
      if (params.target.value === "") {
        setData({
          ...data,
          businessEmail: params.target.value,
          isBusinessValid: false,
          businessEmailErrorMessage: "Business Email should not be empty",
        });
      } 
      else if(bannedDomains.includes(domain)){
        setData({
          ...data,
          businessEmail: params.target.value,
          isBusinessValid: false,
          businessEmailErrorMessage: "This is not a valid Business Email address",
        });
      }
      else {
        setData({
          ...data,
          businessEmail: params.target.value,
          isBusinessValid: false,
          businessEmailErrorMessage: "Email provided is not valid",
        });
      }
    }
  };
  const handleCompany = (params) => {
    if (params.target.value !== "") {
      setData({
        ...data,
        company: params.target.value,
        isCommpanyValid: true,
        companyErrorMessage: "",
      });
    } else {
      setData({
        ...data,
        company: params.target.value,
        isCommpanyValid: false,
        companyErrorMessage: "Company Name should not be empty",
      });
    }
  };
  const handleNumber = (params) => {
    if (params.target.value !== "") {
      setData({
        ...data,
        number: params.target.value,
        isNumberValid: true,
        numberErrorMessage: "",
      });
    } else {
      setData({
        ...data,
        number: params.target.value,
        isNumberValid: false,
        numberErrorMessage: "Phone Number should not be empty",
      });
    }
  };
  const handleComments = (params) => {
    if (params.target.value !== "") {
      setData({
        ...data,
        comments: params.target.value,
        isCommentsValid: true,
        commentsErrorMessage: "",
      });
    } else {
      setData({
        ...data,
        comments: params.target.value,
        isCommentsValid: false,
        commentsErrorMessage: "Comments should not be empty",
      });
    }
  };
  const handleCardClick = (tabkey) => {
    setActiveKey(tabkey);
    setDisplayTab(true);
    if (tabkey === "1") {
      setImageUrl(
        "https://www.virtualdcs.co.uk/files/pricing-tool-images/2.png"
      );
      setHeading(
        <>
          <div className="Title">Veeam Cloud Connect Backup</div>
          <div className="SubTitle">Off Site Backup & Air Gap</div>
        </>
      );
    } else if (tabkey === "2") {
      setImageUrl(
        "https://www.virtualdcs.co.uk/files/pricing-tool-images/5.png"
      );
      setHeading(
        <>
          <div className="Title">Veeam Cloud Connect Replication</div>
          <div className="SubTitle">Off Site Replication and Failover</div>
        </>
      );
    } else if (tabkey === "3") {
      setImageUrl(
        "https://www.virtualdcs.co.uk/files/pricing-tool-images/4.png"
      );
      setHeading(
        <>
          <div className="Title">CloudCover 365</div>
          <div className="SubTitle">Back up your Microsoft Office 365</div>
        </>
      );
    } else if (tabkey === "4") {
      setImageUrl(
        "https://www.virtualdcs.co.uk/files/pricing-tool-images/3.png"
      );
      setHeading(
        <>
          <div className="Title">Veeam Local Backup</div>
          <div className="SubTitle">
            The complete on site & off site backup solution
          </div>
        </>
      );
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        let config = {
          headers: {
            Authorisation: "Bearer Token not need for Display only",
          },
        };
        let tabActiveKey = qparams.get("prod") ? qparams.get("prod") : "";
        if(tabActiveKey !==""){
          setActiveKey(tabActiveKey);
          setDisplayTab(true);
        }
        let orgId = qparams.get("orgId") ? qparams.get("orgId") : "";
        let orgName = qparams.get("orgName") ? qparams.get("orgName") : "";
        let resellerOnlyParam = qparams.get("resellerOnly")
          ? qparams.get("resellerOnly")
          : "false";
        let displayOnlyParam = qparams.get("displayOnly")
          ? qparams.get("displayOnly")
          : "true";
        let collapsedParam = qparams.get("collapsed")
          ? qparams.get("collapsed")
          : "false";
        let hideRRP = qparams.get("help") ? qparams.get("help") : "false";
        let filterid = qparams.get("id")
          ? qparams.get("id").split(",").map(Number)
          : [];
        let internal = qparams.get("internal")
          ? qparams.get("internal")
          : "false";
        if (hideRRP === "true") {
          setHideRRP(false);
        }
        if (internal === "true") {
          setIsBlurred(false);
        }
        if (displayOnlyParam.toLowerCase() === "false") {
          config = {
            headers: {
              Authorisation: `Bearer ` + "",
            },
          };
          setDisplayOnly(false);
        } else if (displayOnlyParam.toLowerCase() === "false") {
          //orgId = resellerId;
          //setDisplayOnly(false)
        }
        if (collapsedParam === "true") {
          setCollapsed(true);
        } else if (collapsedParam === "false") {
          setCollapsed(false);
        }
        if (!orgId && orgId === "")
          console.log("Error fetching Organisation Details");

        const response = await Axios.get(
          "https://365Api-preview.virtualdcs.co.uk:443/api/" +
            //"http://localhost:5001/api/" +
            "TenantService/TenantServices" +
            "/" +
            "AAA" +
            "/" +
            "BBB" +
            "/" +
            resellerOnlyParam +
            "/" +
            displayOnly +
            "/true",
          config
        )
          .then((response) => {
            var result = JSON.parse(response.data);
            setPlanDetails(result.filter((Detail) => Detail.features.length !== 0 && (!Detail.price.hideinPricelist)).sort((a, b) => b.features.length - a.features.length));
            // setPlanDetails(
            //   result
            //     .filter(
            //       (Detail) =>
            //         Detail.features.length !== 0 &&
            //         !filterid.includes(Detail.price.id)
            //     )
            //     .sort((a, b) => b.features.length - a.features.length)
            // );
          })
          .catch((reason) => {
            console.log(reason);
            setShoeLoader(false);
          });

        if (displayOnlyParam === "false") {
          const selectedplandetails = await Axios.get(
            "https://365Api-preview.virtualdcs.co.uk:443/api/" +
              // "http://localhost:5000/api/" +
              "TenantService/TenantServiceById" +
              "/" +
              "AAA" +
              "/" +
              "BBB",
            config
          )
            .then((response) => {
              const selectedPlanDetailsJson = JSON.parse(response.data);
              if (selectedPlanDetailsJson.price !== null) {
                setSelectedPlan(selectedPlanDetailsJson.price.name);
                setResellerId(selectedPlanDetailsJson.price.resellerId);
              }
            })
            .catch((reason) => {
              console.log(reason);
              setShoeLoader(false);
            });
        }
        setShoeLoader(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Box sx={{ width: "100%", typography: "body1" }} className="app-main p-3">
        <Row
          className="m-1 mb-3"
          style={{
            borderRadius: "8px",
            backgroundImage: `url(${PricingToolHeaderBG})`,
            backgroundPosition: "top center",
            backgroundSize: "cover",
          }}
        >
          <Col sm={12} className="p-2" id="header">
            <div className="d-flex justify-content-around align-items-center mb-3">
              <img
                alt="virtualdcs"
                src={imageUrl}
                style={{ height: "130px" }}
                className="header_img"
              ></img>
              <Col sm={6}>{heading}</Col>
              <img
                src="https://www.virtualdcs.co.uk/files/pricing-tool-images/1.png"
                alt="veeam"
                style={{ height: "130px" }}
                className="header_img"
              ></img>
            </div>
          </Col>
        </Row>
        {displayTab ? (
          <Tabs
            defaultActiveKey="1"
            id="justify-tab-example"
            className=""
            justify
            activeKey={activeKey}
            onSelect={handleSelect}
          >
            <Tab eventKey="1" title="Veeam Cloud Connect Backup">
              <CloudConnectBackup
                isBlurred={isBlurred}
                handleShow={handleShow}
                handleRequestShow={handleRequestShow}
              />
            </Tab>
            <Tab eventKey="2" title="Veeam Cloud Connect Replication">
              <CloudConnectReplication
                isBlurred={isBlurred}
                handleShow={handleShow}
                handleRequestShow={handleRequestShow}
                handleCardClick={handleCardClick}
              />
            </Tab>
            <Tab
              eventKey="3"
              title="Microsoft 365 Backup"
              style={{
                overflowX: "hidden",
                overflowY: "auto",
                maxHeight: "calc(70vh - -21px)",
              }}
            >
              <Microsoft365Backup
                hide={qparams.get("id") ? qparams.get("id") : ""}
                selectedPlan={selectedPlan}
                resellerId={resellerId}
                displayOnly={displayOnly}
                collapsed={collapsed}
                planDetails={planDetails}
                showLoader={showLoader}
                isBlurred={isBlurred}
                handleShow={handleShow}
                handleRequestShow={handleRequestShow}
                hideRRP={hideRRP}
              />
            </Tab>
            <Tab eventKey="4" title="Veeam Local Backup">
              <VeeamLocalBackup
                isBlurred={isBlurred}
                handleShow={handleShow}
                handleRequestShow={handleRequestShow}
              />
            </Tab>
          </Tabs>
        ) : (
          <Row>
            <Col sm={6} className="pb-3" onClick={() => handleCardClick("1")}>
              <div
                className="p-2"
                style={{
                  backgroundImage:
                    "linear-gradient(to right, #6dd6eb, #0c33a6)",
                  borderRadius: "13px",
                  width: "100%",
                }}
              >
                <Card>
                  <Card.Body>
                    <img
                      style={{
                        height: "15rem",
                        filter: "drop-shadow(2px 4px 10px white)",
                      }}
                      className="tab-img"
                      alt="cloudcover-backup"
                      src="https://www.virtualdcs.co.uk/files/pricing-tool-images/cloudcover-backup.png"
                    />
                  </Card.Body>
                </Card>
              </div>
            </Col>
            <Col sm={6} className="pb-3" onClick={() => handleCardClick("2")}>
              <div
                className="p-2"
                style={{
                  backgroundImage:
                    "linear-gradient(to right, #6dd6eb, #0c33a6)",
                  borderRadius: "13px",
                  width: "100%",
                }}
              >
                <Card>
                  <Card.Body>
                    <img
                      style={{
                        height: "15rem",
                        filter: "drop-shadow(2px 4px 10px white)",
                      }}
                      className="tab-img"
                      alt="cloudcover-backup"
                      src="https://www.virtualdcs.co.uk/files/pricing-tool-images/cloudcover-replication.png"
                    />
                  </Card.Body>
                </Card>
              </div>
            </Col>
            <Col sm={6} onClick={() => handleCardClick("3")} className="pb-3">
              <div
                className="p-2"
                style={{
                  backgroundImage:
                    "linear-gradient(to right, #6dd6eb, #0c33a6)",
                  borderRadius: "13px",
                  width: "100%",
                }}
              >
                <Card>
                  <Card.Body>
                    <img
                      style={{
                        height: "15rem",
                        filter: "drop-shadow(2px 4px 10px white)",
                      }}
                      className="tab-img"
                      alt="cloudcover-backup"
                      src="https://www.virtualdcs.co.uk/files/pricing-tool-images/CloudCover-365-logo.jpg"
                    />
                  </Card.Body>
                </Card>
              </div>
            </Col>
            <Col sm={6} onClick={() => handleCardClick("4")}>
              <div
                className="p-2"
                style={{
                  backgroundImage:
                    "linear-gradient(to right, #6dd6eb, #0c33a6)",
                  borderRadius: "13px",
                  width: "100%",
                }}
              >
                <Card>
                  <Card.Body>
                    <img
                      style={{
                        height: "15rem",
                        filter: "drop-shadow(2px 4px 10px white)",
                      }}
                      className="tab-img"
                      alt="cloudcover-backup"
                      src="https://www.virtualdcs.co.uk/files/pricing-tool-images/CloudCover-local.png"
                    />
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
        )}
      </Box>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Access Pricing Tools</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Name <span style={{color:'red'}}>*</span></Form.Label>
            <Form.Control
              type="text"
              placeholder="Name"
              isInvalid={!data.isNameValid}
              value={data.name}
              onChange={handleNameChange}
            />
            <Form.Control.Feedback type="invalid">
              {data.nameErrorMessage}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Business Email <span style={{color:'red'}}>*</span></Form.Label>
            <Form.Control
              type="email"
              placeholder="Business Email"
              isInvalid={!data.isBusinessValid}
              value={data.businessEmail}
              onChange={handleBusiness}
            />
            <Form.Control.Feedback type="invalid">
              {data.businessEmailErrorMessage}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Text className="text-muted" style={{fontSize:'10px', lineHeight: '0'}}>
          Your form submission is subject to our company’s{" "}
            <a href="https://www.virtualDCS.co.uk/privacy/" target="_blank">
              {" "}
              privacy policy.
            </a>
            {" "}By providing your information on this page you are consenting to opt in to virtualDCS’ use of your details to contact you regarding the products and services that we offer.
          </Form.Text>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            style={{ color: "#5191ce", background: "white" }}
            onClick={() => {
              togglePriceBlur();
            }}
          >
            Access Pricing Tools
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showRequest}
        onHide={handleRequestClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Request a Free Trial</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Label>Name <span style={{color:'red'}}>*</span></Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter name"
              isInvalid={!data.isNameValid}
              value={data.name}
              onChange={handleNameChange}
            />
            <Form.Control.Feedback type="invalid">
              {data.nameErrorMessage}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address <span style={{color:'red'}}>*</span></Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              isInvalid={!data.isBusinessValid}
              value={data.businessEmail}
              onChange={handleBusiness}
            />
            <Form.Control.Feedback type="invalid">
              {data.businessEmailErrorMessage}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicCompany">
            <Form.Label>Company <span style={{color:'red'}}>*</span></Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter company name"
              isInvalid={!data.isCommpanyValid}
              value={data.company}
              onChange={handleCompany}
            />
            <Form.Control.Feedback type="invalid">
              {data.companyErrorMessage}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicCompany">
            <Form.Label>Phone number <span style={{color:'red'}}>*</span></Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter phone number"
              isInvalid={!data.isNumberValid}
              value={data.number}
              onChange={handleNumber}
            />
            <Form.Control.Feedback type="invalid">
              {data.numberErrorMessage}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.Comments">
            <Form.Label>Comments <span style={{color:'red'}}>*</span></Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              isInvalid={!data.isCommentsValid}
              value={data.comments}
              onChange={handleComments}
            />
            <Form.Control.Feedback type="invalid">
              {data.commentsErrorMessage}
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleRequestClose}>
            Close
          </Button>
          <Button
            style={{ color: "#5191ce", background: "white" }}
            onClick={() => {
              handleRequestSubmit();
            }}
          >
            Request Trial
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer className="p-3" position='top-end' style={{ zIndex: 10000 }}>
        <Toast show={showToast} onClose={() => setShowToast(false)} className="d-inline-block m-1" bg={toastVariant} autohide delay={4000}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toastVariant=== 'danger'?'Error':'Success' }</strong>
          </Toast.Header>
          <Toast.Body className="text-white">
            {toastMessage}
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
}
