import React, { useState, useEffect } from "react";
import "./Microsoft365Pricing.css";
import { Button, Modal, OverlayTrigger, Tooltip, Card } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import Axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { CurrencyPound, CurrencyEuro } from "react-bootstrap-icons";
import { trim } from "lodash";
export default function Microsoft365Pricing(props) {
  // const [selectedPlan, setSelectedPlan] = useState("");
  // const [resellerId, setResellerId] = useState(0);
  // const [displayOnly, setDisplayOnly] = useState(true);
  // const [collapsed, setCollapsed] = useState(true);
  const [clickedPlan, setClickedPlan] = useState("");
  // const [planDetails, setPlanDetails] = useState([]);
  // const [showLoader, setShoeLoader] = useState(true);
  const [show, setShow] = useState(false);
  const [qparams, setQparams] = useState(
    new URLSearchParams(window.location.search)
  );

  const handleClose = () => {
    setShow(false);
    setClickedPlan("");
  };
  const handleShow = () => setShow(true);
  const handlePlanSelection = (planName, planId) => {
    setClickedPlan(planName);
    handleShow();
  };
 
  const [hoveredPlan, setHoveredPlan] = useState(null);
  const handleMouseEnter = (planName) => {
    setHoveredPlan(planName);
  };

  const handleMouseLeave = () => {
    setHoveredPlan(null);
  };

  const renderTooltip = (props) => (
    <Tooltip id={`tooltip-${props.id}`} {...props}>
      {props.text}
    </Tooltip>
  );


  return (
    <>
      <div className=" p-0">
        <div className="centered-div">
          {props.showLoader ? (
            <div
              className="justify-content-center align-items-center d-flex h-100"
              style={{ minHeight: "35vh" }}
            >
              <Spinner
                animation="border"
                style={{ color: "#669ed4" }}
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : props.planDetails.length !== 0 ? (
            <>
              <div
                className="d-flex"
                style={{
                  position: "sticky",
                  top: "-1px",
                  background: "#ffffff",
                  zIndex: "100",
                  minWidth: "fit-content",
                }}
              >
                <div
                  className="col-12 col-xl-2 d-none d-xl-block"
                  style={{ position: "relative" }}
                >
                  <div className="pricing-features">
                    <ul className="list-group list-group-flush">
                      <li
                        className="list-group-item bg-blue-1 d-flex align-items-center justify-content-between"
                        style={{ height: "47.5px" }}
                      ></li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-xl-10 d-flex">
                  {props.planDetails.map(
                    (Detail, index) =>
                      Detail.features.length !== 0 && (
                        <div
                          onMouseEnter={() =>
                            handleMouseEnter(Detail.price.name)
                          }
                          onMouseLeave={handleMouseLeave}
                          className={`plans heading ${
                            props.selectedPlan === Detail.price.name && !props.displayOnly
                              ? "selectedplan"
                              : ""
                          } ${
                            hoveredPlan === Detail.price.name && !props.displayOnly
                              ? "hover-effect"
                              : ""
                          } ${
                            clickedPlan === Detail.price.name && !props.displayOnly
                              ? "hover-effect"
                              : ""
                          }`}
                        >
                          <div
                            className="pricing-title bg-blue-4 py-1 d-flex flex-column align-items-center justify-content-center"
                            style={{ height: "3rem" }}
                          >
                            <h6 className="m-0 text-center">
                              {Detail.price.name}
                            </h6>
                          </div>
                        </div>
                      )
                  )}
                </div>
              </div>
              <Accordion
                defaultActiveKey={props.collapsed ? ["0"] : ["0", "1"]}
                alwaysOpen
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Plan Details</Accordion.Header>
                  <Accordion.Body className="d-flex p-0">
                    <div
                      className="col-12 col-xl-2 d-none d-xl-block"
                      style={{ position: "relative" }}
                    >
                      <div className="pricing-features">
                        <ul className="list-group list-group-flush">
                          {props.planDetails.length !== 0 &&
                            props.planDetails[0].features
                              .filter(
                                (FeatureDetail) => !FeatureDetail.smallPrint
                              )
                              .sort((a, b) => a.sortOrder - b.sortOrder)
                              .map((feature, index) => (
                                <li className="list-group-item bg-blue-1 d-flex align-items-center justify-content-between">
                                  <span>{feature.featureName}</span>
                                  <OverlayTrigger
                                    key="sort-1"
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={(props) =>
                                      renderTooltip({
                                        ...props,
                                        id: "sort-1",
                                        text: feature.featureDescription,
                                      })
                                    }
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="20"
                                      fill="currentColor"
                                      className="bi bi-info-circle"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
                                    </svg>
                                  </OverlayTrigger>
                                </li>
                              ))}
                        </ul>
                      </div>
                    </div>
                    <div className="col-12 col-xl-10 d-flex">
                      {props.planDetails.map(
                        (Detail, index) =>
                          Detail.features.length !== 0 && (
                            <div
                              onMouseEnter={() =>
                                handleMouseEnter(Detail.price.name)
                              }
                              onMouseLeave={handleMouseLeave}
                              className={`plans ${
                                props.selectedPlan === Detail.price.name &&
                                !props.displayOnly
                                  ? "selectedplan"
                                  : ""
                              } ${
                                hoveredPlan === Detail.price.name &&
                                !props.displayOnly
                                  ? "hover-effect"
                                  : ""
                              } ${
                                clickedPlan === Detail.price.name &&
                                !props.displayOnly
                                  ? "hover-effect"
                                  : ""
                              }`}
                            >
                              <div className="pricing-detail">
                                <ul className="list-group list-group-flush">
                                  {Detail.features
                                    .filter(
                                      (FeatureDetail) =>
                                        !FeatureDetail.smallPrint
                                    )
                                    .sort((a, b) => a.sortOrder - b.sortOrder)

                                    .map((feature) =>
                                      feature.featureValue.toLowerCase() ===
                                      "true" ? (
                                        <li className="list-group-item bg-blue-2 d-flex flex-column align-items-center justify-content-center">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="32"
                                            height="20"
                                            fill="currentColor"
                                            className="bi bi-check text-success"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"></path>
                                          </svg>
                                          <span className="text-center text-secondary d-xl-none">
                                            {feature.featureName}
                                          </span>
                                        </li>
                                      ) : feature.featureValue.toLowerCase() ===
                                        "false" ? (
                                        <li className="list-group-item bg-blue-2 d-flex flex-column align-items-center justify-content-center">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="32"
                                            height="20"
                                            fill="currentColor"
                                            className="bi bi-x text-danger"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
                                          </svg>

                                          <span className="text-center text-secondary d-xl-none">
                                            {feature.featureName}
                                          </span>
                                        </li>
                                      ) : trim(feature.featureValue) === "" ? (
                                        <li className="list-group-item bg-blue-2 d-flex flex-column align-items-center justify-content-center">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="32"
                                            height="20"
                                            fill="currentColor"
                                            className="bi bi-x text-danger"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
                                          </svg>

                                          <span className="text-center text-secondary d-xl-none">
                                            {feature.featureName}
                                          </span>
                                        </li>
                                      ) : isNaN(
                                          parseInt(feature.featureValue)
                                        ) ? (
                                        <li className="list-group-item bg-blue-1 d-flex flex-column align-items-center justify-content-center">
                                          <span style={{ height: "20px" }}>
                                            {feature.featureValue}
                                          </span>
                                          <span className="text-center text-secondary d-xl-none">
                                            {feature.featureName}
                                          </span>
                                        </li>
                                      ) : (
                                        <li className="list-group-item bg-blue-1 d-flex flex-column align-items-center justify-content-center">
                                          <span style={{ height: "20px" }}>
                                            {feature.featureValue}{" "}
                                            {feature.featureUnit}
                                          </span>
                                          <span className="text-center text-secondary d-xl-none">
                                            {feature.featureName}
                                          </span>
                                        </li>
                                      )
                                    )}
                                </ul>
                              </div>
                            </div>
                          )
                      )}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                {props.hideRRP && <Accordion.Item eventKey="1">
                  <Accordion.Header>RRP Per User Per Month</Accordion.Header>
                  {props.isBlurred ? (
                    <Accordion.Body className="d-flex p-0">
                      <div
                        onClick={props.handleShow}
                        className="heartbeat p-2 ps-4 pe-4 three"
                      >
                        <span>Show Pricing</span>
                      </div>
                      <div
                        className="col-12 col-xl-2 d-none d-xl-block"
                        style={{ position: "relative" }}
                      >
                        <div className="pricing-features">
                          <ul className="list-group list-group-flush">
                            {props.planDetails.length !== 0 &&
                              props.planDetails[0].priceBook
                                .sort((a, b) => a.id - b.id)
                                .map((Book, index) =>
                                  Book.sband >= 500 ? (
                                    <li className="list-group-item bg-blue-2 d-flex align-items-center justify-content-between">
                                      <span style={{ height: "20px" }}>
                                        500+
                                      </span>
                                    </li>
                                  ) : (
                                    <li className="list-group-item bg-blue-1 d-flex align-items-center justify-content-between">
                                      <span style={{ height: "20px" }}>
                                        {Book.sband}
                                        {"-"}
                                        {Book.eband}
                                      </span>
                                    </li>
                                  )
                                )}
                          </ul>
                        </div>
                      </div>
                      <div className="col-12 col-xl-10 d-flex blur ">
                        {props.planDetails.map(
                          (Detail, index) =>
                            Detail.priceBook.length !== 0 && (
                              <div
                                onMouseEnter={() =>
                                  handleMouseEnter(Detail.price.name)
                                }
                                onMouseLeave={handleMouseLeave}
                                className={`plans ${
                                  props.selectedPlan === Detail.price.name &&
                                  !props.displayOnly
                                    ? "selectedplan"
                                    : ""
                                } ${
                                  hoveredPlan === Detail.price.name &&
                                  !props.displayOnly
                                    ? "hover-effect"
                                    : ""
                                } ${
                                  clickedPlan === Detail.price.name &&
                                  !props.displayOnly
                                    ? "hover-effect"
                                    : ""
                                }`}
                              >
                                <div className="pricing-detail">
                                  <ul className="list-group list-group-flush">
                                    {Detail.priceBook
                                      .sort((a, b) => a.id - b.id)
                                      .map((perUser, index) =>
                                        perUser.sband >= 500 && props.displayOnly ? (
                                          <li className="list-group-item bg-blue-1 d-flex flex-column align-items-center justify-content-center">
                                            <span style={{ height: "20px" }}>
                                              contact us
                                            </span>
                                            <span className="text-center text-secondary d-xl-none">
                                              500+
                                            </span>
                                          </li>
                                        ) : (
                                          <li className="list-group-item bg-blue-1 d-flex flex-column align-items-center justify-content-center">
                                            <span style={{ height: "20px" }}>
                                              {perUser.currency === "GBP" && (
                                                <CurrencyPound
                                                  style={{ color: "black" }}
                                                />
                                              )}{" "}
                                              {perUser.currency === "EUR" && (
                                                <CurrencyEuro
                                                  style={{ color: "black" }}
                                                />
                                              )}
                                              0
                                            </span>
                                            <span className="text-center text-secondary d-xl-none">
                                              {perUser.sband}
                                              {"-"}
                                              {perUser.eband}
                                            </span>
                                          </li>
                                        )
                                      )}
                                  </ul>
                                </div>
                              </div>
                            )
                        )}
                      </div>
                    </Accordion.Body>
                  ) : (
                    <Accordion.Body className="d-flex p-0">
                      <div
                        className="col-12 col-xl-2 d-none d-xl-block"
                        style={{ position: "relative" }}
                      >
                        <div className="pricing-features">
                          <ul className="list-group list-group-flush">
                            {props.planDetails.length !== 0 &&
                              props.planDetails[0].priceBook
                                .sort((a, b) => a.id - b.id)
                                .map((Book, index) =>
                                  Book.sband >= 500 ? (
                                    <li className="list-group-item bg-blue-2 d-flex align-items-center justify-content-between">
                                      <span style={{ height: "20px" }}>
                                        500+
                                      </span>
                                    </li>
                                  ) : (
                                    <li className="list-group-item bg-blue-1 d-flex align-items-center justify-content-between">
                                      <span style={{ height: "20px" }}>
                                        {Book.sband}
                                        {"-"}
                                        {Book.eband}
                                      </span>
                                    </li>
                                  )
                                )}
                          </ul>
                        </div>
                      </div>
                      <div className="col-12 col-xl-10 d-flex">
                        {props.planDetails.map(
                          (Detail, index) =>
                            Detail.priceBook.length !== 0 && (
                              <div
                                onMouseEnter={() =>
                                  handleMouseEnter(Detail.price.name)
                                }
                                onMouseLeave={handleMouseLeave}
                                className={`plans ${
                                  props.selectedPlan === Detail.price.name &&
                                  !props.displayOnly
                                    ? "selectedplan"
                                    : ""
                                } ${
                                  hoveredPlan === Detail.price.name &&
                                  !props.displayOnly
                                    ? "hover-effect"
                                    : ""
                                } ${
                                  clickedPlan === Detail.price.name &&
                                  !props.displayOnly
                                    ? "hover-effect"
                                    : ""
                                }`}
                              >
                                <div className="pricing-detail">
                                  <ul className="list-group list-group-flush">
                                    {Detail.priceBook
                                      .sort((a, b) => a.id - b.id)
                                      .map((perUser, index) =>
                                        perUser.sband >= 500 && props.displayOnly ? (
                                          <li className="list-group-item bg-blue-1 d-flex flex-column align-items-center justify-content-center">
                                            <span style={{ height: "20px" }}>
                                              contact us
                                            </span>
                                            <span className="text-center text-secondary d-xl-none">
                                              500+
                                            </span>
                                          </li>
                                        ) : perUser.name.trim() ==="CloudCover 365 - Enterprise Plus - Per User" ? (<li className="list-group-item bg-blue-1 d-flex flex-column align-items-center justify-content-center">
                                          <span style={{ height: "20px" }}>
                                            contact us
                                          </span>
                                          <span className="text-center text-secondary d-xl-none">
                                          {perUser.sband}
                                              {"-"}
                                              {perUser.eband}
                                          </span>
                                        </li>) :(
                                          <li className="list-group-item bg-blue-1 d-flex flex-column align-items-center justify-content-center">
                                            <span style={{ height: "20px" }}>
                                              {perUser.currency === "GBP" && (
                                                <CurrencyPound
                                                  style={{ color: "black" }}
                                                />
                                              )}{" "}
                                              {perUser.currency === "EUR" && (
                                                <CurrencyEuro
                                                  style={{ color: "black" }}
                                                />
                                              )}
                                              {perUser.price}
                                            </span>
                                            <span className="text-center text-secondary d-xl-none">
                                              {perUser.sband}
                                              {"-"}
                                              {perUser.eband}
                                            </span>
                                          </li>
                                        )
                                      )}
                                  </ul>
                                </div>
                              </div>
                            )
                        )}
                      </div>
                    </Accordion.Body>
                  )}
                </Accordion.Item>}
                
              </Accordion>
              {!props.displayOnly && (
                <div
                  className="d-flex"
                  style={{
                    position: "sticky",
                    bottom: 0,
                    width: "100%",
                    top: "0px",
                    background: "rgb(255, 255, 255)",
                    zIndex: "100",
                  }}
                >
                  <div
                    className="col-12 col-xl-2 d-none d-xl-block"
                    style={{ position: "relative" }}
                  >
                    <div className="pricing-features border-bottom">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item bg-blue-1 d-flex align-items-center justify-content-between">
                          <span
                            style={{
                              height: "30px",
                              fontSize: "1rem",
                              fontWeight: "bold",
                            }}
                          ></span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12 col-xl-10 d-flex">
                    {props.planDetails.map(
                      (Detail, index) =>
                        Detail.features.length !== 0 && (
                          <div
                            onMouseEnter={() =>
                              handleMouseEnter(Detail.price.name)
                            }
                            onMouseLeave={handleMouseLeave}
                            className={`plans plan-checkbox ${
                              props.selectedPlan === Detail.price.name && !props.displayOnly
                                ? "selectedplan"
                                : ""
                            } ${
                              hoveredPlan === Detail.price.name && !props.displayOnly
                                ? "hover-effect"
                                : ""
                            } ${
                              clickedPlan === Detail.price.name && !props.displayOnly
                                ? "hover-effect"
                                : ""
                            }`}
                            style={{
                              borderTopLeftRadius: "0",
                              borderTopRightRadius: "0",
                            }}
                          >
                            <div
                              className="justify-content-center d-flex p-2"
                              style={{ borderBottom: "1px solid #dee2e6" }}
                            >
                              <input
                                type="checkbox"
                                onClick={() => {
                                  if (props.selectedPlan !== Detail.price.name) {
                                    handlePlanSelection(
                                      Detail.price.name,
                                      Detail.price.id
                                    );
                                  }
                                }}
                                htmlFor={`checkbox_${Detail.price.id}`}
                                id={`checkbox_${Detail.price.id}`}
                                checked={props.selectedPlan === Detail.price.name}
                                className="form-check-input"
                                value="1"
                                readOnly
                              />
                              <label className="form-check-label"></label>
                            </div>
                          </div>
                        )
                    )}
                  </div>
                </div>
              )}
              <div>
                <ul className="note-list pt-2">
                  {props.planDetails
                    .map(
                      (Detail, index) =>
                        Detail.features.length !== 0 &&
                        Detail.features
                          .filter((fd) => fd.smallPrint)
                          .sort((a, b) => a.sortOrder - b.sortOrder)
                          .map((fe) => fe.featureDescription)
                    )
                    .flat()
                    .filter(
                      (value, index, self) => self.indexOf(value) === index
                    )
                    .map((uniqueDescription, innerindex) => (
                      <li key={"note_" + innerindex} style={{textAlign:'left'}}>{uniqueDescription}</li>
                    ))}
                </ul>
              </div>{" "}
            </>
          ) : (
            <div
              className="justify-content-center align-items-center d-flex"
              style={{ height: "100vh" }}
            >
              <h3>No Active Price Model to display</h3>
            </div>
          )}
        </div>

        {/* <Modal
          show={show}
          onHide={handleClose}
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Body>
            Are you sure you wish to use the <b> {clickedPlan} </b>price model,
            your bill will reflect the selected model.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary " onClick={handlePlanConfirm}>
              Yes
            </Button>
            <Button variant="danger" onClick={handleClose}>
              No
            </Button>
          </Modal.Footer>
        </Modal> */}
      </div>
    </>
  );
}
