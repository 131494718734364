import React, { useState } from "react";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import "./CloudConnectBackup.css";
import VCCReplicationJson from "./PriceFiles/VCCReplication.json";
import {
  Row,
  Col,
  Form,
  InputGroup,
  Card,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import VirtualDCS from "../src/Images/virtualDCS-logo.png";
import Veeam from "../src/Images/veeam.png";
export const CloudConnectReplication = (props) => {
  const [serverValue, setServerValue] = useState("");
  const [dataProtectValue, setDataProtectValue] = useState("");
  const [memoryValue, setMemoryValue] = useState("");
  const [serverCost, setServerCost] = React.useState(0.0);
  const [total, setTotal] = React.useState(0.0);
  const [memoryCost, setMemoryCost] = React.useState(0.0);
  const [averagePrice, setAveragePrice] = React.useState(0.0);

  const handleServerSliderChange = (event, newValue) => {
    setServerValue(newValue);
    if (newValue === 0) {
      setServerCost(0);
      calculatePrice(dataProtectValue, memoryValue, memoryCost, 0, newValue, 0);
    } else {
      setServerCost(VCCReplicationJson.Server.PerPrice);
      calculatePrice(
        dataProtectValue,
        memoryValue,
        memoryCost,
        VCCReplicationJson.Server.PerPrice,
        newValue,
        0
      );
    }
  };

  const handleServerChange = (event) => {
    var targetValue =
      event.target.value === "" ? 0 : Number(event.target.value);

    console.log(event.target.value);

    if (targetValue === 0) {
      setServerCost(0);
      calculatePrice(
        dataProtectValue,
        memoryValue,
        memoryCost,
        0,
        targetValue,
        0
      );
    } else {
      if (targetValue > 100) targetValue = 100;
      setServerCost(VCCReplicationJson.Server.PerPrice);
      calculatePrice(
        dataProtectValue,
        memoryValue,
        memoryCost,
        VCCReplicationJson.Server.PerPrice,
        targetValue,
        0
      );
    }
    setServerValue(targetValue);
  };

  const handleMemorySliderChange = (event, newValue) => {
    setMemoryValue(newValue);
    if (newValue === 0) {
      setMemoryCost(0);
    } else {
      setMemoryCost(VCCReplicationJson.Memory.PerPrice);
      calculatePrice(
        dataProtectValue,
        newValue,
        memoryCost,
        serverCost,
        serverValue,
        0
      );
    }
  };

  const handleMemoryChange = (event) => {
    if (
      event.target.value === "" ||
      (/^\d*$/.test(event.target.value) &&
        parseInt(event.target.value) % 2 === 0)
    ) {
      var targetValue =
        event.target.value === "" ? 0 : Number(event.target.value);

      if (targetValue === 0) {
        setMemoryCost(0);
        calculatePrice(
          dataProtectValue,
          targetValue,
          0,
          serverCost,
          serverValue,
          0
        );
      } else {
        if (targetValue > 500) targetValue = 500;
        setMemoryCost(VCCReplicationJson.Memory.PerPrice);
        calculatePrice(
          dataProtectValue,
          targetValue,
          VCCReplicationJson.Memory.PerPrice,
          serverCost,
          serverValue,
          0
        );
      }
      setMemoryValue(targetValue);
    }
  };

  const handleDataProtectSliderChange = (event, newValue) => {
    setDataProtectValue(newValue);
    if (newValue === 0) {
      calculatePrice(0);
    } else {
      calculatePrice(
        event.target.value,
        memoryCost,
        memoryValue,
        serverCost,
        serverValue,
        0
      );
    }
    setDataProtectValue(newValue);
  };

  const handleDataProtectChange = (event) => {
    setDataProtectValue(
      event.target.value === "" ? 0 : Number(event.target.value)
    );
    calculatePrice(
      event.target.value,
      memoryCost,
      memoryValue,
      serverCost,
      serverValue,
      0
    );
  };

  const calculatePrice = (
    newValue,
    memoryCost,
    memoryValue,
    serverCost,
    serverValue,
    discount
  ) => {
    var cost = 0;
    var slideqty = newValue * 1000;
    for (let index = 0; index < VCCReplicationJson.DiskBand.length; index++) {
      const element = VCCReplicationJson.DiskBand[index];
      if (slideqty <= JSON.parse(element.Start)) {
        cost = slideqty * element.Price;
        break;
      }
    }

    if (slideqty === 0) {
      setAveragePrice(0);
      setTotal(0);
    } else {
      var disc = 1 - Number(discount) / 100;
      var amount = Number(cost / slideqty);
      setAveragePrice((amount * disc).toFixed(4));
    }
    if (memoryCost !== "") cost = cost + memoryCost * memoryValue;
    if (serverCost !== "") cost = cost + serverCost * serverValue;

    console.log("==================");
    console.log(cost);
    console.log(disc);
    setTotal(cost.toFixed(2));
  };

  function valuetext(value) {
    return `${value} Server(s)`;
  }

  const marks = [
    {
      value: 0,
      label: "0 Server",
    },
    {
      value: 100,
      label: "100 Servers",
    },
  ];

  const dataMarks = [
    {
      value: 0,
      label: "0 TB",
    },
    {
      value: 50,
      label: "50 TB",
    },
  ];

  const memoryMarks = [
    {
      value: 0,
      label: "0 GB",
    },
    {
      value: 500,
      label: "500 GB",
    },
  ];
  const renderTooltip = (props) => (
    <Tooltip id={`tooltip-${props.id}`} {...props}>
      {props.text}
    </Tooltip>
  );
  return (
    <>
    <Row className="p-3 pe-4" style={{ borderRadius: "8px" }}>
      <Col sm={8}>
        <Card style={{ borderColor: "white" }}>
          <Card.Body>
            <Row>
              <Col sm={12} className="justify-content-start d-flex">
                <OverlayTrigger
                  key="sort-1"
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(props) =>
                    renderTooltip({
                      ...props,
                      id: "sort-1",
                      text: "Select the number of servers you want to replicate to our secure Data Centre.",
                    })
                  }
                >
                  <span className="font-weight-bold textprimary me-2 labels">
                    Number of servers to protect
                  </span>
                </OverlayTrigger>
              </Col>
              <Col sm={10}>
                <Slider
                  min={0}
                  max={100}
                  step={1}
                  getAriaValueText={valuetext}
                  value={typeof serverValue === "number" ? serverValue : 0}
                  onChange={handleServerSliderChange}
                  aria-labelledby="input-slider"
                  valueLabelDisplay="auto"
                  marks={marks}
                />
              </Col>
              <Col sm={2}>
                <InputGroup className="mb-3">
                  <Form.Control
                    type="number"
                    value={serverValue}
                    placeholder="0"
                    aria-label="Server(s)"
                    onChange={handleServerChange}
                    min="0"
                    max="100"
                    step="1"
                    aria-describedby="basic-addon1"
                  />
                  <InputGroup.Text id="basic-addon1">Server(s)</InputGroup.Text>
                </InputGroup>
              </Col>
              <Col sm={10}></Col>
            </Row>
            <Row>
              <Col sm={12} className="justify-content-start d-flex">
                <OverlayTrigger
                  key="sort-1"
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(props) =>
                    renderTooltip({
                      ...props,
                      id: "sort-1",
                      text: "Estimate the amount of off-site disk space you require to store your data. i.e. If you already have some, how large are your local vbk files?",
                    })
                  }
                >
                  <span className="font-weight-bold textprimary me-2 labels">
                    Data to protect
                  </span>
                </OverlayTrigger>
              </Col>
              <Col sm={10}>
                <Slider
                  min={0}
                  max={50}
                  step={1}
                  getAriaValueText={valuetext}
                  value={
                    typeof dataProtectValue === "number" ? dataProtectValue : 0
                  }
                  onChange={handleDataProtectSliderChange}
                  aria-labelledby="input-slider"
                  valueLabelDisplay="auto"
                  marks={dataMarks}
                />
              </Col>
              <Col sm={2}>
                <InputGroup className="mb-3">
                  <Form.Control
                    type="number"
                    value={dataProtectValue}
                    placeholder="0"
                    aria-label="TB"
                    onChange={handleDataProtectChange}
                    min="0"
                    max="50"
                    step="1"
                    aria-describedby="basic-addon1"
                  />
                  <InputGroup.Text id="basic-addon1">TB</InputGroup.Text>
                </InputGroup>
              </Col>
              <Col sm={10}></Col>
            </Row>
            <Row>
              <Col sm={12} className="justify-content-start d-flex">
                <OverlayTrigger
                  key="sort-1"
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(props) =>
                    renderTooltip({
                      ...props,
                      id: "sort-1",
                      text: "For VMware, the amount selected can be what you think you�ll need in the event of an incident For Hyper-V, the amount must match your live servers' allocation.",
                    })
                  }
                >
                  <span className="font-weight-bold textprimary me-2 labels">
                    Memory for reservation
                  </span>
                </OverlayTrigger>
              </Col>
              <Col sm={10}>
                <Slider
                  min={0}
                  max={500}
                  step={2}
                  getAriaValueText={valuetext}
                  value={typeof memoryValue === "number" ? memoryValue : 0}
                  onChange={handleMemorySliderChange}
                  aria-labelledby="input-slider"
                  valueLabelDisplay="auto"
                  marks={memoryMarks}
                />
              </Col>
              <Col sm={2}>
                <InputGroup className="mb-3">
                  <Form.Control
                    type="number"
                    value={memoryValue}
                    placeholder="0"
                    aria-label="GB"
                    onChange={handleMemoryChange}
                    min="0"
                    max="500"
                    step="2"
                    aria-describedby="basic-addon1"
                  />
                  <InputGroup.Text id="basic-addon1">GB</InputGroup.Text>
                </InputGroup>
              </Col>
              <Col sm={10}></Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      <Col sm={4} className="modern-card">
        {props.isBlurred ? (
          <>
            <div
              onClick={props.handleShow}
              className="heartbeat p-2 ps-4 pe-4 two"
            >
              <span>Show Pricing</span>
            </div>
            <div className={`blurred-card blur bllurred-two p-2`}>
              <Card className="shadow-sm rounded-lg">
                <Card.Body>
                  <Card.Title className="font-weight-bold textprimary">
                    Cost
                  </Card.Title>
                  <Card.Text className="font-weight-medium">
                    £&nbsp;0/ Server
                  </Card.Text>
                  <Card.Title className="font-weight-bold textprimary mt-3">
                    Cost for Memory
                  </Card.Title>
                  <Card.Text className="font-weight-medium">£&nbsp;0</Card.Text>
                  <Card.Title className="font-weight-bold textprimary mt-3">
                    Average Price
                  </Card.Title>
                  <Card.Text className="font-weight-medium">
                    £&nbsp;0/ GB
                  </Card.Text>
                  <Card.Title className="font-weight-bold textprimary mt-3">
                    Total price excluding VAT
                  </Card.Title>
                  <Card.Text className="font-weight-medium">
                    £&nbsp;0/ mo
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </>
        ) : (
          <>
            <div
              className="p-2"
              style={{
                backgroundImage: "linear-gradient(to right, #6dd6eb, #0c33a6)",
                borderRadius: "13px",
                width:'100%'
              }}
            >
              <Card className="shadow-sm rounded-lg">
                <Card.Body>
                  <Card.Title className="font-weight-bold textprimary">
                    Cost
                  </Card.Title>
                  <Card.Text className="font-weight-medium">
                    £&nbsp;{serverCost}/ Server
                  </Card.Text>
                  <Card.Title className="font-weight-bold textprimary mt-3">
                    Cost for Memory
                  </Card.Title>
                  <Card.Text className="font-weight-medium">
                    £&nbsp;{memoryCost}
                  </Card.Text>
                  <Card.Title className="font-weight-bold textprimary mt-3">
                    Average Price
                  </Card.Title>
                  <Card.Text className="font-weight-medium">
                    £&nbsp;{averagePrice}/ GB
                  </Card.Text>
                  <Card.Title className="font-weight-bold textprimary mt-3">
                    Total price excluding VAT
                  </Card.Title>
                  <Card.Text className="font-weight-medium">
                    £&nbsp;{total}/ mo
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </>
        )}
      </Col>
    </Row>
    <Box className="p-3 pt-0 scaleup">
        <div
          className="bg-white p-3"
          style={{ borderRadius: "8px", border: "1px solid #5091ce",textAlign:'left' }}
        >
          <div className="col-12 justify-content-start d-flex">
            <span className="text-muted me-1">* Click <a href="#" style={{fontSize:'13px'}} className="text-primary text-decoration-underline me-1" onClick={props.handleRequestShow}>
              HERE
            </a> to claim a 30-day trial of Veeam Cloud Connect Replication.</span>
          </div>
          <div className="col-12 justify-content-start d-flex">
            <span className="text-muted">
              * You must already have Veeam Backup and Replication software. Discover rental prices <a href="#" style={{fontSize:'13px'}} className="text-primary text-decoration-underline me-2" onClick={() => props.handleCardClick("4")}>
              HERE.
            </a>
            </span>
          </div>
          <div className="col-12 justify-content-start d-flex">
            <span className="text-muted">
              * This pricing is intended as a guide, and we are happy to discuss your requirements in more detail.
            </span>
          </div>

          <div className="col-12 justify-content-start d-flex">
            <span className="text-muted">
              * No ingress or egress charges.
            </span>
          </div>
          <div className="col-12 justify-content-start d-flex">
        <span className="text-muted me-1">
          * For full service terms and conditions click <a href="https://www.virtualdcs.co.uk/downloads" style={{fontSize:'13px'}} className="text-primary text-decoration-underline"> HERE.</a>
          </span>
          
        
      </div>
        </div>
      </Box>
    </>
  );
};

export default CloudConnectReplication;
